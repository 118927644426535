$theme-colors: (
    "info": hsl(252, 100%, 86%),
    "primary": rgb(12,98,251),
    "secondary": rgb(27,225, 242),
    "danger": hsl(354, 100%, 65%),
    "success": hsl(71, 100%, 45%),
    "warning": #ffc107,
    "light": #f8f9fa,
    "dark": #212529

);
@import "bootstrap/scss/bootstrap.scss";
.card-deck {
    justify-content: center;
}

.aocUser {
    cursor: pointer;
    border : 2px solid transparent;
    list-style-type: none;
    width : 400px;
    padding: 5px;
    &.selected {
        border : 2px solid green;
    }
    &.danger {
        background-color: pink;
    }
}

.mx-1.dropdown {
    max-width: 100%;
    .dropdown-toggle {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.img-wrapper {
    width: 100%;
    position: relative;
    &::after {
        display: block;
        content: '';
        padding-top: 100%;
    }
    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media print {
    .hide-print {
        display: none;
    }
}

@include media-breakpoint-down(md) {
    html {
        font-size: .7rem;
    }
}